import { combineReducers } from "redux";
import errorReducer from "./errorReducer";
import loginReducer from "./loginReducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [],
};


const rootReducer = combineReducers({
  errorReducer,
  loginReducer,
});

export default persistReducer(persistConfig, rootReducer);