import React from 'react';
import { AgriShop, NewTab, EmbedScreen, Login,EmbedScreenCheck } from './screens';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ProtectedRoute from './components/ProtectedRoute';

const App = () => {
  return (
    <>
      <Router>
      <Routes>
        <Route  exact path={`/agri`} element={<AgriShop/>}></Route>
      </Routes>
      <Routes>
        <Route  exact path={`/password`} element={<NewTab/>}></Route>
      </Routes>
      <Routes>
        <Route  exact path={`/metabase`} element={<EmbedScreen/>}></Route>
      </Routes>
      <Routes>
        <Route  exact path={`/login`} element={<Login/>}></Route>
      </Routes>
      <Routes>
        <Route element={<ProtectedRoute />}>
            <Route  exact path={`/dashboards`} element={<EmbedScreenCheck/>}/>
        </Route>
      </Routes>
    </Router>
    </>
  )
}

export default App;